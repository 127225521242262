var toggleMenu = (function () {
  var menuDiv = document.getElementById("menu");
  var menuContent = document.getElementById("menu-content");
  var cname = "menu-open";

  //close on outside click
  document.addEventListener('click', function (event) {
    try {
      if (event.target instanceof Node) {
        var isClickInside = menuDiv.contains(event.target);

        if (!isClickInside) {
          closeMenu();
        }
      }
    } catch(e){
      // noop
    }
  });

  function openMenu() {
    menuContent.style.display = "block";
    setCookie(cname, "true");
  }

  function closeMenu() {
    menuContent.style.display = "none";
    setCookie(cname, "");
  }

  // we preserve menu state should using a cookie.
  function handleCookie(value) {
    //if a cookie is set, close the menu
    if (value) {
      openMenu();
    } else {
      closeMenu();
    }
  }

  handleCookie(getCookie(cname));

  return function toggleMenu() {
    if (window.getComputedStyle(menuContent).display === "none") {
      openMenu();
    } else {
      closeMenu();
    }
  }
})();
