var carouselNext = (function(){
function imageHidden(image) {
  return window.getComputedStyle(image).display === "none";
}

function show(image) {
  image.style.display = "inline-block";
}

function hide(image) {
  return image.style.display = "none";
}

function isPicture(element) {
    return !!element.className && !!/picture/.test(element.className);
}

return function carouselNext(e, id) {
  var carousel = document.getElementById(id);
  var images = [];
  var next = null;
  for(var i = 0; i < carousel.childNodes.length; i++) {
    var node = carousel.childNodes[i];
    if (isPicture(node)) {
      images.push(node);
      if(!imageHidden(node)) {
        hide(node);
        next = images.length;
      }
    }
  }
  if (images.length === 0) {
    return;
  }
  if (next === images.length) {
    next = 0;
  }
  show(images[next]);
}
})();
