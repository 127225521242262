// FLYOUT

var handleFlyout = (function () {
  var contentPaneClassname = "content-pane";
  var openerClassname = "flyout-open";
  var classname = "flyout";
  var resizeHandler = false;
  var opener = document.getElementsByClassName(openerClassname);
  var lastScrollPosition = 0;
  var animationInProgress = false;

  function positionFlyout(flyout, button) {
    flyout.style.top = 'auto';
    flyout.style.left = 'auto';
    /*
    document.body.style.position = 'static';
    if (window.innerWidth <= cssBreakPoint) {
      document.body.style.position = 'fixed'; // prevent scrolling
      return;
    }
    */
    var pageWidth = window.innerWidth > cssMaxWidth ? cssMaxWidth : window.innerWidth;
    var flyoutWidth = flyout.offsetWidth;
    var safetyMargin = 15; //add some safety margin
    var absTargetOffset = getPageOffset(button);
    var isTargetOnTheRight = absTargetOffset.left > pageWidth / 2;
    var parentOffsetLeft = absTargetOffset.left - button.offsetLeft;
    var top = Math.max(button.offsetTop - 50, 0);
    var left = (isTargetOnTheRight) ?
      button.offsetLeft - flyoutWidth :
      (button.offsetLeft + button.clientWidth);
    if (left && parentOffsetLeft + left + flyoutWidth + safetyMargin > window.innerWidth) {
      left = window.innerWidth - parentOffsetLeft - flyoutWidth - safetyMargin;
    }
    if (left < 0 && left < -parentOffsetLeft) {
      left = -parentOffsetLeft;
    }

    flyout.style.top = top + 'px';
    flyout.style.left = left + 'px';
  }

  function flyoutIsOpen(flyout) {
    return flyout.style.display === "block";
  }

  function closeFlyout(flyout) {
    flyout.style.display = "none";
    document.body.style.position = 'static';
    resizeHandler && window.removeEventListener('resize', resizeHandler);
  }

  function openFlyout(flyout, target) {
    closeFlyouts();
    flyout.style.display = "block";
    positionFlyout(flyout, target);
    resizeHandler = function () {
      positionFlyout(flyout, target);
    };
    resizeHandler && window.addEventListener('resize', resizeHandler);
  }

  function toggleFlyout(flyout, button) {
    if (flyoutIsOpen(flyout)) {
      closeFlyout(flyout)
    } else {
      openFlyout(flyout, button);
    }
  }

  function closeFlyouts() {
    resizeHandler && window.removeEventListener('resize', resizeHandler);
    var flyouts = document.getElementsByClassName(classname);
    for (var i = 0; i < flyouts.length; i++) {
      if (flyoutIsOpen(flyouts[i])) {
        closeFlyout(flyouts[i]);
      }
    }
  }

// close flyouts on outside click
  document.addEventListener('click', function (e) {
    let clickedOutside = true;
    getPath(e).forEach(function (item) {
      if (!clickedOutside) {
        return;
      }
      if (item.className === contentPaneClassname || item.className === openerClassname) {
        clickedOutside = false;
      }
    });
    if (clickedOutside) {
      closeFlyouts();
    }
  });


  window.addEventListener('scroll', function (e) {
    var numFrames = 100;
    var frameDuration = 10;
    var animationDuration = numFrames * frameDuration;

    function fadeIn(elem) {
      var s = 0;
      var id = setInterval(frame, frameDuration);

      function frame() {
        if (s == numFrames) {
          elem.style.opacity = 1;
          clearInterval(id);
        } else {
          s++;
          elem.style.opacity = 1 / numFrames * s;
        }
      }
    }

    function fadeOut(elem) {
      var s = numFrames;
      var id = setInterval(frame, frameDuration);

      function frame() {
        if (s == 0) {
          elem.style.opacity = 0;
          clearInterval(id);
        } else {
          s--;
          elem.style.opacity = 1 / numFrames * s;
        }
      }
    }

    function animate(callback) {
      for (var i = 0; i < opener.length; i++) {
        var o = opener[i];
        callback(o.getElementsByTagName('span')[0]);
      }
    }

    lastScrollPosition = window.scrollY;
    if (!animationInProgress) {
      animate(fadeIn);
      animationInProgress = true;
      window.setTimeout(function () {
        animate(fadeOut);
        window.setTimeout(function () {
          animationInProgress = false;
        }, animationDuration + 500)
      }, animationDuration);
    }
  });

  return function handleFlyout(button, id) {
    // e = Mouse click event.
    var flyout = document.getElementById(id);
    toggleFlyout(flyout, button);
  };
})();
